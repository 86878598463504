import Preact from "preact";
import { useState } from "preact/hooks";

import { useNavState } from "./Context.tsx";

export function MobileToggleView(props) {
  const { open: isOpen, onClick } = props;
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <div class={`menu-icn ${isOpen ? "open" : ""}`} onClick={handleClick}>
      <i></i>
    </div>
  );
}

export default function MobileToggle() {
  const { navState, toggleNav } = useNavState();

  return <MobileToggleView open={navState} onClick={toggleNav} />;
}
